import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: none;
  background-color: #ffafcc;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #0d1b2a;
  font-size: 16px;
  :hover {
    background-color: #e695a2;
  }
`;

