import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLocation  } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-ML6YX4EXPT'); 

const SendPageView = () => {
    const location = useLocation()

    useEffect(() => {
        ReactGA.event({
            category:"Page View",
            action: "Navigated to " + location.pathname,
        });
    }, [location]);
    return null;
}

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
